nz-pagination{
  ul{
    li.ant-pagination-prev {
      &.ant-pagination-disabled{
        opacity: 0.5;
      }

      button{
        display: grid !important;
        place-content: center;
        border-width: 0 !important;

        span.anticon.anticon-left{
          display: none;
        }

        &::after{
          content: url("../../assets/images/icons/caret-left-solid.svg");
          width: 0.8rem;
          height: 2.6rem;
        }
      }
    }

    li.ant-pagination-next {
      &.ant-pagination-disabled{
        opacity: 0.5;
      }

      button{
        display: grid !important;
        place-content: center;
        border-width: 0 !important;

        span.anticon.anticon-right{
          display: none;
        }

        &::after{
          content: url("../../assets/images/icons/caret-right-solid.svg");
          width: 0.8rem;
          height: 2.6rem;
        }
      }
    }

    li.ant-pagination-jump-prev{

      .ant-pagination-item-container{
        display: grid;
        place-content: center;
        height: 100%;
      }

      .ant-pagination-item-ellipsis{
        color: black !important;
      }
    }

    li.ant-pagination-jump-next{

      .ant-pagination-item-container{
        display: grid;
        place-content: center;
        height: 100%;
      }

      .ant-pagination-item-ellipsis{
        color: black !important;
      }
    }

    li{
      border-width: 0 !important;

      &:hover{
        @apply bg-gray-50;
        border-radius: 0.5rem;
      }
    }
  }
}
