@import 'src/styles/variables.scss';

.label-float {
  background: white;
  top: 0rem;
  line-height: 1.4rem;
  transform: translateY(-50%) scale(0.7143);
  transform-origin: 0;
  font-size: 1.6rem;
}

nz-upload{
  display: flex;

  .ant-upload.ant-upload-select{
    width: 100%;
  }

  [nz-upload-btn]{
    position: relative;
    width: 100%;
    border-radius: 1rem;
    border: 0.1rem $color-gray-7 solid;
    color: $color-black-1;
    text-align: start;
    font-size: 1.4rem;
    height: 100%;
    width: 100%;
    padding: 0.8rem 1.6rem;
    transition: all 300ms;

    &:hover,&:focus,&:focus-within{
      outline: none !important;
      box-shadow: none;
      border-color: $color-secondary;
    }

    [nz-button]{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    label{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.6rem;
      pointer-events: none;
      transform-origin: 0;
      transition-duration: 200ms;
      color: $color-gray-8;
    }
  }

  &.hasValue{
    label{
      @apply label-float text-black;
    }
  }

  &.invalid{
    .ant-upload{
      @apply border-danger;
    }

    label{
      @apply text-danger;
    }
  }
}
