.cdk-overlay-pane{
  height: fit-content;
}

.ant-dropdown{
  height: fit-content;
  margin: 0;

  .dropdown-menu{
    @apply overflow-hidden p-0;
    background-color: white;
    box-shadow: 0px 0.3rem 0.6rem #00000029;
    border-radius: 1rem;
    min-width: 20rem;
  }

  .dropdown-menu-item{
    width: 100%;
    display: flex;
    align-items: center;
    color: black;
    font-size: 1.2rem;
    background-color: #ffffff;
    border-radius: 1rem;

    &:hover{
      background-color: #f9fafb;
      border-radius: 0;
    }
  }
}
