@import '@angular/cdk/overlay-prebuilt.css';

@import './fonts.scss';

@import './variables.scss';

//components
@import './components/buttons.scss';
@import './components/table.scss';
@import './components/tooltip.scss';
@import './components/navs.scss';
@import './components/toggle.scss';
@import './components/textarea.scss';
@import './components/badge.scss';
@import './components/collapsible-menu.scss';

// ngZorro component's styles
@import './components/nz-collapse.scss';
@import './components/nz-table.scss';
@import './components/nz-dropdown-menu.scss';
@import './components/nz-tooltip.scss';
@import './components/nz-modal.scss';
@import './components/nz-pagination.scss';
@import './components/nz-switch.scss';
@import './components/nz-notification.scss';
@import './components/nz-upload.scss';

html, body{
  font-family: 'Roboto Regular';
}

::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0rem 0rem 0.5rem rgba(0, 0, 0, 0.5);
  background-color: $color-gray-3;
  border: 0.5rem;
}

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  box-shadow: inset 0rem 0rem 0.5rem rgba(36, 33, 33, 0.3);
}

.hide-scroollbar{
  &::-webkit-scrollbar{
    display: none;
  }
  overflow: -moz-scrollbars-none;
}
