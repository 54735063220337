@import 'src/styles/variables.scss';

.menu-item{
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.active-link{
    .menu-item-icon{
      background: #E2E6F3;
    }
  }

  .menu-item-icon{
    @apply text-auth-navy-blue;
    min-height: 3rem;
    min-width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99999999px;
  }

  .menu-item-text{
    white-space: nowrap;
  }
}

core-collapsible-menu{

  .wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 200ms ease-out;
    border-bottom: 1px solid $color-gray-6;
  }

  .wrapper.is-open {
    grid-template-rows: 1fr;
  }

  .inner {
    overflow: hidden;
  }

  fa-icon{
    @apply text-14 rotate-0 shrink-0 transition-all duration-200;

    &.active{
      @apply rotate-180-clockwise;
    }
  }
}
