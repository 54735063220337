.bup-table,
.cdk-table {
  width: 100%;
  max-width: 100%;
  font-size: 0.875rem;
  thead {
    .bup-th,
    .cdk-header-cell {
      color: $color-black-1;
      background-color: $color-gray-9;
      border-top: 0.1rem solid $color-gray-6;
      border-bottom: 0.1rem solid $color-gray-6;
      padding: 1.5rem;
      text-align: center;
      font-size: 1.4rem;
      line-height: 0.9rem;
      font-family: $font-sans-medium;
      font-weight: normal;
      white-space: nowrap;
    }
  }
  tbody {
    .bup-td,
    .cdk-cell {
      text-align: center;
      padding: 1rem;
      font-size: 1.4rem;
      color: black;
      border-bottom: 0.1rem solid $color-gray-6;
      line-height: 0.9rem;
      font-family: $font-sans;
      white-space: nowrap;
      background-color: $color-white_1;
    }
  }

  .cdk-table-sticky {
    box-shadow: inset 0.6rem 0rem 0.5rem -0.5rem #0000001A;
    z-index: 0 !important;
  }
}

.table-rounded th:first-child {
  border-radius: 16px 0 0 0;
}

.table-rounded th:last-child {
  border-radius: 0 16px 0 0;
}

.table-fixed-layout {
  table-layout: fixed;
}

.select-pagination {
  select {
    background-image: none;
    border-color: $color-gray-6;
  }

  .select-icon {
    position: absolute;
    top: 0.2rem;
    right: 1.1rem;
  }
}
