.ant-table-thead > tr > th {
  @apply bg-gray-9;

  padding: 0.8rem 0.8rem;
  border-top: 0.1rem solid #E5E7EB;
  border-bottom: 0.1rem solid #E5E7EB;
  font-family: Roboto Medium, sans-serif;

  &::before{
    display: none;
  }
}

.ant-table-tbody > tr > td {
  border-bottom: 0.1rem solid #E5E7EB;
  transition: background 0.3s;
  padding: 0.9rem 0.8rem;
}

//! Eliminar el hover de la fila
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  @apply bg-white;
}
