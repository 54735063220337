//Colors

$color-primary: theme('colors.primary');
$color-secondary: theme('colors.secondary');
$color-accent: theme('colors.accent');
$color-gray-1: theme('colors.gray-1');
$color-gray-2: theme('colors.gray-2');
$color-gray-3: theme('colors.gray-3');
$color-gray-4: theme('colors.gray-4');
$color-gray-5: theme('colors.gray-5');
$color-gray-6: theme('colors.gray-6');
$color-gray-7: theme('colors.gray-7');
$color-gray-8: theme('colors.gray-8');
$color-gray-9: theme('colors.gray-9');
$color-gray-10: theme('colors.gray-010');
$color-danger: theme('colors.danger');
$color-danger-accent: theme('colors.danger-accent');
$color-success: theme('colors.success');
$color-background-soft-1: theme('colors.color-background-soft-1');
$color-background-soft-2: theme('colors.color-background-soft-2');
$color-green-neutral-1: theme('colors.green-neutral-1');
$color-blue-accent-1: theme('colors.blue-accent-1');
$color-black-1: theme('colors.black-1');
$color-white-1: theme('colors.white_1');

$color-auth-primary: theme('colors.auth.primary');
$color-auth-navy-blue: theme('colors.auth.navy-blue');
$color-auth-gray-01: theme('colors.auth.gray-01');

//Font size

$font-size-10: theme('fontSize.10');
$font-size-12: theme('fontSize.12');
$font-size-14: theme('fontSize.14');
$font-size-16: theme('fontSize.16');
$font-size-20: theme('fontSize.20');
$font-size-24: theme('fontSize.24');
$font-size-28: theme('fontSize.28');

//Font Family

$font-sans: theme('fontFamily.sans');
$font-sans-bold: theme('fontFamily.sans-bold');
$font-sans-medium: theme('fontFamily.sans-medium');

//Border Radius

$border-radius-10: theme('borderRadius.10');
$border-radius-15: theme('borderRadius.15');
$border-radius-20: theme('borderRadius.20');

//Box Shadow

$box-shadow-sm: 0rem 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
$box-shadow-md: theme('boxShadow.md');
$box-shadow-lg: 0rem 0.4rem 0.8rem rgba(21, 34, 50, 0.1);
$box-shadow-sm-right: 0.2rem 0rem 0.3rem rgba(0, 0, 0, 0.1);
