.ant-collapse{
  @apply rounded-[1rem] shadow-01 overflow-hidden border-b border-solid border-gray-6;

  .ant-collapse-item{
    @apply border-b-0;
  }
}

.collapse-indicator{
  @apply w-1 h-1 rounded-full flex-shrink-0 mr-3 transition-all bg-gray-6;

  &.active{
    @apply bg-secondary;
  }
}

.ant-collapse-header{
  padding: 0 !important;
  padding-right: 4.8rem !important;

  &[aria-expanded="false"]{
    @apply bg-white;
  }

  &[aria-expanded="true"]{
    @apply bg-gray-9;
  }

  .collapse-custom-header {
    cursor: default;
    width: 100%;
    padding: 1.3rem 0 1.3rem 1.6rem;
  }

  .collapse-expand-btn{
    @apply bg-white hover:bg-gray-9 absolute right-[9px] top-[50%] translate-y-[-50%] w-8 h-8 flex justify-center items-center transition-all duration-300 rounded-full;

    &.active{
      @apply bg-gray-9 hover:bg-white;
    }

    .collapse-expand-icon{
      @apply text-14 rotate-0 shrink-0 transition-all duration-200;

      &.active{
        @apply rotate-180-clockwise;
      }
    }
  }
}
