@import '../variables.scss';

nz-notification{
  .ant-notification-notice.custom-notification-error{
    border-radius: 1.5rem;
    background-color: $color-danger-accent;
    width: 28.6rem;
    padding: 2rem .8rem;

    .ant-notification-notice-description{
      color: $color-danger;
      padding-right: 3rem;
    }

    .ant-notification-notice-close{
      color: $color-danger;

      &:hover{
        color: $color-danger;
      }
    }
  }

  .ant-notification-notice.custom-notification-error-large{
    border-radius: 1.5rem;
    background-color: $color-danger-accent;
    width: 55rem;
    padding: 2rem .8rem;

    .ant-notification-notice-description{
      color: $color-danger;
      padding-right: 3rem;
    }

    .ant-notification-notice-close{
      color: $color-danger;

      &:hover{
        color: $color-danger;
      }
    }
  }
}
