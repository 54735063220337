/*tooltip*/
.viewTooltip {
  &:hover .tooltip {
      visibility: visible;
      opacity: 1;
  }
}

.tooltip {
  position: absolute;
  top: -3rem;
  left: -1.8rem;
  visibility: hidden;
  opacity: 0;
  background-color: #003257;
  color: white;
  padding: .4rem 1rem;
  border-radius: .8rem;
  transition: all ease-in-out .2s;
}

.tooltip::after {
  content: "";
  display: block;
  background-color: #003257;
  width: 1.3rem;
  height: 1.3rem;
  transform: rotate(45deg);
  position: absolute;
  right: 2.7rem;
}
