.toggle{

  width: 4.5rem;
  height: 2.4rem;

  background-color: $color-gray-6;

  position: relative;

  border-radius: 9999px;
  
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  
  &::after{
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;

    width: 1.8rem;
    height: 1.8rem;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    background-color: white;
    box-shadow: 0px 0.1rem 0.2rem #0000001A;
    border-radius: 9999px;
    transform: translateX(0%);
  }

  

}

.peer:checked + .toggle{
  background-color: $color-secondary;
  &::after{
    transform: translateX(calc(100% + 0.3rem));
  }

}