
  .nav {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: $color-gray-6;
    border-radius: 1rem;
    padding-top: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;

    &:hover .nav-icon span {
      opacity: 1;
      left: 0.8rem;
    }
  }

  .nav-icon {
    @apply w-9.5 h-9.5 flex justify-center items-center text-16 text-secondary relative;

    span {
      position: absolute;
      opacity: 0;
      left: -1.4rem;
      transition: 0.3s ease-out;
    }

  }
