.btn {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.6rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 3.2rem;
  border-radius: 1rem;
  border-width: 0.1rem;
  border-style: solid;
  font-family: $font-sans-medium;
  transition: background-color 0.2s ease;

  &.btn-icon {
    padding: 0px !important;
  }

  &.btn-primary {
    background-color: $color-primary;
    color: white;
    border: 0.1rem solid $color-primary;

    &:disabled {
      background-color: #D2D5DA;
      color: white;
      cursor: not-allowed;
      border-color: #D2D5DA;
    }
  }

  &.btn-outline.btn-primary {
    background-color: transparent;
    border: 0.1rem solid $color-gray-6;
    color: $color-black-1;
  }

  &.btn-outline-black {
    background-color: white;
    border-color: black;
    color: black;

    &:disabled {
      background-color: white;
      color: #D2D5DA;
      cursor: not-allowed;
      border-color: #D2D5DA;
      opacity: 0.5;
    }
  }

  &.btn-outline-white {
    background-color: white;
    border-color: $color-gray-6;
    color: black;
    box-shadow: $box-shadow-lg;

    &:hover {
      border-color: black;
    }
  }

  &.btn-outline.btn-primary:disabled {
    border: 1px solid $color-gray-5;
    color: $color-gray-5;
    cursor: not-allowed;
  }

  &.btn-secondary {
    background-color: $color-secondary;
    border: 1px solid $color-secondary;
    color: white;
  }

  &.btn-auth-primary {
    background-color: $color-auth-primary;
    color: white;
    font-family: $font-sans-bold;
  }

  .btn-icon.btn-icon--left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
    margin-left: -0.9375rem;

  }

  &.btn-lg {
    block-size: 4.8rem;
  }

  &.btn-md {
    block-size: 4rem;
  }

}

.btn-filter {
  display: flex;
  justify-content: start;
  height: 3.2rem;
  width: 6.2rem;
}

.btn-actions {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  font-size: 1.6rem;
  color: rgb(156, 163, 175);
  transition: all 0.3s;

  &:hover, &:focus, &:focus-within {
    background-color: rgb(229, 231, 235);
    color: black;
  }

}
