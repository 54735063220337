.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 9rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: $color-gray-6;
  color: black;
  padding: 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.badge.active {
  background-color: rgb(64 151 255 / 0.2);
  color: $color-secondary;
}

.badge.success {
  background-color: rgba(223, 248, 235, 1);
  color: $color-success;
}

.badge.warning {
  background-color: rgba(255, 244, 198, 1);
  color: #D0AA00;
}

.badge.danger {
  background-color: rgba(252, 241, 240, 1);
  color: $color-danger;
}


