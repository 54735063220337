@font-face {
  font-family: 'Roboto Regular';
  src: url('/assets/fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('/assets/fonts/Roboto-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto Medium';
  src: url('/assets/fonts/Roboto-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light Italic';
  src: url('/assets/fonts/Roboto-LightItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aller Bold';
  src: url('/assets/fonts/Aller.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/assets/fonts/Muli.ttf');
  font-weight: normal;
  font-style: normal;
}
