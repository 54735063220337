/* You can add global styles to this file, and also import other style files */

html {
   font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

@import './styles/globals.scss';

.ng-tooltip {
  position: fixed;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  background-color: #003257;
  color: white;
  box-shadow: 0px 0.1rem 0.3rem #00000029;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }
}

.ng-tooltip.top::before {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #003257;
  bottom: -0.5rem;
  left: calc(50% - 0.5rem);
}

.ng-tooltip.right::before {
  border-right: 0.5rem solid #003257;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  top: 50%;
  transform: translateY(-50%);
  left: -0.5rem;
}

.ng-tooltip.bottom::before {
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #003257;
  top: -0.5rem;
  left: calc(50% - 0.5rem);
}

.ng-tooltip.left::before {
  border-left: 0.5rem solid #003257;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  top: 50%;
  transform: translateY(-50%);
  right: -0.5rem;

}

.ng-tooltip-show {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

// Overlay backdrop styling
.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// Overlay panel styling
.overlay-panel {
  background: transparent 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.125rem 0.25rem #0000001A;
  border-radius: 1.5rem;
  // min-width: 39.6rem;
}

.backdrop {
  position: relative;
  z-index: 30;
}

.backdrop-container {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  overflow-y: auto;
}

.backdrop-content {
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 1.6rem;
}

.dialog {
  width: 100%;
  position: relative;
  overflow: auto;
  border-radius: 1.5rem;
  background-color: white;
  text-align: left;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dialog-header {
  background-color: white;
  text-align: center;
  border-bottom: 1px solid;
  --tw-border-opacity: 1; border-color: rgb(195 205 223 / var(--tw-border-opacity));
  padding: 1.2rem;
  position: relative;
}

.dialog-body {
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.dialog-footer {
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.container-header-fixed {
  max-height: calc(100vh - 25px);
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
}

.cost-table {
  .ant-table-tbody > tr > td {
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
  }

  .ant-table-tbody tr:last-child td {
    border-bottom: none;
  }
}

.ant-modal-mask {
  opacity: 0 !important;
}

@media screen and (max-width: 640px) {
  .backdrop-content{
    align-items: center;
    padding: 0;
  }

  .dialog {
    width: 100%;
    max-width: 39.6rem;

    @media (max-width: 767px) {
      max-width: calc(100vw - 2rem);
      margin: 1.4rem auto;
    }
  }

  .dialog-footer {
    display: flex;
    flex-direction: row-reverse;
  }
}
