.ant-modal-content{
  border-radius: 1.5rem;

  .ant-modal-body{
    padding: 0;
  }
}

nz-modal-container{
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  &.welcome-modal{
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.loading-modal-content {
    background-color: rgba(0, 0, 0, 0.16);
  }

  .ant-modal.loading-modal .ant-modal-content{
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .ant-modal.welcome-modal .ant-modal-content{
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
