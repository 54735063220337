.ant-switch{
  @apply bg-gray-6;

  min-width: 4.5rem;
  height: 2.4rem;
  line-height: 2.4rem;

    .ant-switch-handle{
      top: 0.3rem;
      left: 0.3rem;
    }

  .ant-click-animating-node{
    display: none;
  }
}

.ant-switch-checked {
  @apply bg-secondary;

  .ant-switch-handle {
    left: calc(100% - 1.8rem - 0.3rem);
  }
}

.ant-switch:focus{
  box-shadow: none;
}

nz-switch[ng-reflect-model="true"] + label {
  @apply text-secondary;
}

nz-switch[ng-reflect-model="true"][ng-reflect-nz-disabled="true"] + label {
  @apply text-secondary opacity-50;
}

.doc-switch {
  .ant-switch {
    height: 1.4rem;
    min-width: 2rem;
    width: 2rem;
    line-height: 0.9rem;

    .ant-switch-handle{
      top: 0.3rem;
      left: 0.3rem;
      height: 0.8rem;
      width: 0.8rem;
    }
    &.ant-switch-checked {
      .ant-switch-handle {

        left: calc(100% - 0.8rem - 0.3rem);
      }
    }
  }

  .invalid {
    .ant-switch {
      @apply bg-danger;
    }
  }
}
